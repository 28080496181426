import {
  AfterViewInit, ChangeDetectorRef,
  Component, HostBinding,
  Input, OnChanges, SimpleChanges, TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {CommonModule, NgTemplateOutlet} from "@angular/common";

@Component({
  selector: 'app-icons',
  standalone: true,
  imports: [NgTemplateOutlet, CommonModule],
  templateUrl: './icons.component.html',
  styleUrl: './icons.component.scss',

})
export class IconsComponent implements AfterViewInit, OnChanges {
  // Config
  @Input({required: true}) name!: string;
  @Input() width: number | string = 40;
  @Input() height: number | string = 40;
  @Input() fillColor?: string;
  @Input() rotate?: number | string = 0;

  // Icons
  @ViewChild('anal') anal!: TemplateRef<any>;
  @ViewChild('avatar') avatar!: TemplateRef<any>;
  @ViewChild('all_type') all_type!: TemplateRef<any>;
  @ViewChild('arrow') arrow!: TemplateRef<any>;
  @ViewChild('arrow_2') arrow_2!: TemplateRef<any>;
  @ViewChild('arrow_small') arrow_small!: TemplateRef<any>;
  @ViewChild('attachment') attachment!: TemplateRef<any>;
  @ViewChild('attention') attention!: TemplateRef<any>;
  @ViewChild('body_type') body_type!: TemplateRef<any>;
  @ViewChild('bdsm') bdsm!: TemplateRef<any>;
  @ViewChild('boost') boost!: TemplateRef<any>;
  @ViewChild('booking') booking!: TemplateRef<any>;
  @ViewChild('butt') butt!: TemplateRef<any>;
  @ViewChild('breasts') breasts!: TemplateRef<any>;
  @ViewChild('calendar') calendar!: TemplateRef<any>;
  @ViewChild('calendar_2') calendar_2!: TemplateRef<any>;
  @ViewChild('clock') clock!: TemplateRef<any>;
  @ViewChild('clock_1') clock_1!: TemplateRef<any>;
  @ViewChild('close') close!: TemplateRef<any>;
  @ViewChild('close_1') close_1!: TemplateRef<any>;
  @ViewChild('close_2') close_2!: TemplateRef<any>;
  @ViewChild('couples') couples!: TemplateRef<any>;
  @ViewChild('comment') comment!: TemplateRef<any>;
  @ViewChild('content') content!: TemplateRef<any>;
  @ViewChild('content_hub') content_hub!: TemplateRef<any>;
  @ViewChild('cookie') cookie!: TemplateRef<any>;
  @ViewChild('cosplay') cosplay!: TemplateRef<any>;
  @ViewChild('donate') donate!: TemplateRef<any>;
  @ViewChild('donate_1') donate_1!: TemplateRef<any>;
  @ViewChild('dots_menu') dots_menu!: TemplateRef<any>;
  @ViewChild('dildo') dildo!: TemplateRef<any>;
  @ViewChild('exhibitionism') exhibitionism!: TemplateRef<any>;
  @ViewChild('edit') edit!: TemplateRef<any>;
  @ViewChild('edit_1') edit_1!: TemplateRef<any>;
  @ViewChild('eye') eye!: TemplateRef<any>;
  @ViewChild('eye_1') eye_1!: TemplateRef<any>;
  @ViewChild('eye_closed') eye_closed!: TemplateRef<any>;
  @ViewChild('eye_color') eye_color!: TemplateRef<any>;
  @ViewChild('favorite') favorite!: TemplateRef<any>;
  @ViewChild('favorite_fill') favorite_fill!: TemplateRef<any>;
  @ViewChild('female') female!: TemplateRef<any>;
  @ViewChild('fire') fire!: TemplateRef<any>;
  @ViewChild('gender') gender!: TemplateRef<any>;
  @ViewChild('geo') geo!: TemplateRef<any>;
  @ViewChild('heart') heart!: TemplateRef<any>;
  @ViewChild('height_icon') height_icon!: TemplateRef<any>;
  @ViewChild('heart_1') heart_1!: TemplateRef<any>;
  @ViewChild('heart_fill') heart_fill!: TemplateRef<any>;
  @ViewChild('hair_color') hair_color!: TemplateRef<any>;
  @ViewChild('hair_style') hair_style!: TemplateRef<any>;
  @ViewChild('hair_length') hair_length!: TemplateRef<any>;
  @ViewChild('home') home!: TemplateRef<any>;
  @ViewChild('image') image!: TemplateRef<any>;
  @ViewChild('image_1') image_1!: TemplateRef<any>;
  @ViewChild('latex') latex!: TemplateRef<any>;
  @ViewChild('layout_grid') layout_grid!: TemplateRef<any>;
  @ViewChild('layout_line') layout_line!: TemplateRef<any>;
  @ViewChild('list') list!: TemplateRef<any>;
  @ViewChild('lists') lists!: TemplateRef<any>;
  @ViewChild('lock') lock!: TemplateRef<any>;
  @ViewChild('logout') logout!: TemplateRef<any>;
  @ViewChild('logo') logo!: TemplateRef<any>;
  @ViewChild('male') male!: TemplateRef<any>;
  @ViewChild('messages') messages!: TemplateRef<any>;
  @ViewChild('messages_2') messages_2!: TemplateRef<any>;
  @ViewChild('microphone') microphone!: TemplateRef<any>;
  @ViewChild('more') more!: TemplateRef<any>;
  @ViewChild('non_read') non_read!: TemplateRef<any>;
  @ViewChild('notifications') notifications!: TemplateRef<any>;
  @ViewChild('pause') pause!: TemplateRef<any>;
  @ViewChild('penis') penis!: TemplateRef<any>;
  @ViewChild('play') play!: TemplateRef<any>;
  @ViewChild('plus') plus!: TemplateRef<any>;
  @ViewChild('place') place!: TemplateRef<any>;
  @ViewChild('post') post!: TemplateRef<any>;
  @ViewChild('price') price!: TemplateRef<any>;
  @ViewChild('profile') profile!: TemplateRef<any>;
  @ViewChild('profile_1') profile_1!: TemplateRef<any>;
  @ViewChild('read') read!: TemplateRef<any>;
  @ViewChild('referral') referral!: TemplateRef<any>;
  @ViewChild('roleplay') roleplay!: TemplateRef<any>;
  @ViewChild('scale') scale!: TemplateRef<any>;
  @ViewChild('schedule_message') schedule_message!: TemplateRef<any>;
  @ViewChild('skin_tone') skin_tone!: TemplateRef<any>;
  @ViewChild('sex_doll') sex_doll!: TemplateRef<any>;
  @ViewChild('sex') sex!: TemplateRef<any>;
  @ViewChild('search') search!: TemplateRef<any>;
  @ViewChild('settings') settings!: TemplateRef<any>;
  @ViewChild('settings_slider') settings_slider!: TemplateRef<any>;
  @ViewChild('share') share!: TemplateRef<any>;
  @ViewChild('skin') skin!: TemplateRef<any>;
  @ViewChild('statistic') statistic!: TemplateRef<any>;
  @ViewChild('statements') statements!: TemplateRef<any>;
  @ViewChild('tag') tag!: TemplateRef<any>;
  @ViewChild('tag_1') tag_1!: TemplateRef<any>;
  @ViewChild('tattoo') tattoo!: TemplateRef<any>;
  @ViewChild('text_post') text_post!: TemplateRef<any>;
  @ViewChild('trash') trash!: TemplateRef<any>;
  @ViewChild('upload') upload!: TemplateRef<any>;
  @ViewChild('upload_2') upload_2!: TemplateRef<any>;
  @ViewChild('verify') verify!: TemplateRef<any>;
  @ViewChild('video') video!: TemplateRef<any>;
  @ViewChild('video_1') video_1!: TemplateRef<any>;
  @ViewChild('video_off') video_off!: TemplateRef<any>;
  @ViewChild('volume') volume!: TemplateRef<any>;
  @ViewChild('wave') wave!: TemplateRef<any>;

  // Payments
  @ViewChild('MasterCard') MasterCard!: TemplateRef<any>;
  @ViewChild('MasterCard_card') MasterCard_card!: TemplateRef<any>;
  @ViewChild('paypal') paypal!: TemplateRef<any>;
  @ViewChild('stripe') stripe!: TemplateRef<any>;
  @ViewChild('Visa') Visa!: TemplateRef<any>;
  @ViewChild('Visa_card') Visa_card!: TemplateRef<any>;
  @ViewChild('AmericanExpress') AmericanExpress!: TemplateRef<any>;
  @ViewChild('Discover') Discover!: TemplateRef<any>;
  @ViewChild('ApplePay') ApplePay!: TemplateRef<any>;

  // Social
  @ViewChild('facebook') facebook!: TemplateRef<any>;
  @ViewChild('google') google!: TemplateRef<any>;
  @ViewChild('instagram') instagram!: TemplateRef<any>;
  @ViewChild('tiktok') tiktok!: TemplateRef<any>;
  @ViewChild('twitter') twitter!: TemplateRef<any>;


  @ViewChild('container', {read: ViewContainerRef}) containerRef!: ViewContainerRef;

  @HostBinding('style.rotate') styleRotate = '0deg';

  constructor(private _changeRef: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['rotate']) {
      this.styleRotate = this.rotate + 'deg';
    }
    if (changes['name']) {
      this.attachIcon();
    }
  }

  ngAfterViewInit() {
    this.attachIcon();
  }

  private attachIcon() {
    const index = Object.keys(this).indexOf(this.name);
    this.containerRef?.clear();
    if (index > -1) {
      this.containerRef?.createEmbeddedView(Object.values(this)[index]);
      this._changeRef.detectChanges();
    }
  }
}
